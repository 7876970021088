<template>
    <base-table models-name="clienti"
                model-ref="il cliente"
                new-msg="un nuovo cliente"
                :is-loading="isLoading"
                :headers="headers"
                :can-create="$store.state.authModule.utente.CAN_CREATE_CLIENTE"
                @changeOptions="onChangeOptions($event)"
    >

        <template #header="{ onChangeFilter }">
            <clienti-table-header @changeOptions="onChangeOptions($event)"
                                  @changeFilter="onChangeFilter"
            ></clienti-table-header>
        </template>

        <template #CLC_MIN_INIZIO="{ item }">
            <i :class="!!item.CLC_MIN_INIZIO && (getData(new Date(), 'yyyyMMDD') < getData(item.CLC_MIN_INIZIO, 'yyyyMMDD') ) ? 'error--text' : undefined">
                {{ !item.CLC_MIN_INIZIO ? '-' : getData(item.CLC_MIN_INIZIO) }}
            </i>
        </template>

        <template #CLC_MAX_SCADENZA="{ item }">
            <i :class="!!item.CLC_MAX_SCADENZA && (getData(new Date(), 'yyyyMMDD') > getData(item.CLC_MAX_SCADENZA, 'yyyyMMDD') ) ? 'error--text' : undefined">
                {{ !item.CLC_MAX_SCADENZA ? '-' : getData(item.CLC_MAX_SCADENZA) }}
            </i>
        </template>

        <template #CLC_NORM_COUNT="{ item }">
            <button-tooltip x-small
                            fab
                            :text="''+item.CLC_NORM_COUNT"
                            color="primary"
                            :to="'/secure/contratti?cln=' + item.ID_REC"
                            exact
                            :tooltip="item.NOME + ' possiede ' + item.CLC_NORM_COUNT + ' contratti definitivi'"
            ></button-tooltip>
        </template>

        <template #CLC_PREV_COUNT="{ item }">
            <button-tooltip x-small
                            fab
                            :text="''+item.CLC_PREV_COUNT"
                            color="primary"
                            :to="'/secure/contratti?cln=' + item.ID_REC"
                            exact
                            :tooltip="item.NOME + ' possiede ' + item.CLC_PREV_COUNT + ' preventivi in attesa'"
            ></button-tooltip>
        </template>

        <template #EV_ALL_COUNT="{ item }">
            <button-tooltip fab
                            x-small
                            color="primary"
                            :text="'' + item.EV_ALL_COUNT"
                            :to="'/secure/eventi?cln=' + item.ID_REC"
                            exact
            >
                <h-row>
                    <h-col class="py-0 px-6">
                        <h-row class="pb-2">
                            <span>
                                TOTALE: {{ item.EV_ALL_COUNT }} / {{ item.CLC_ALL_COUNT }} (+{{ item.CLC_ALL_COUNT_PREV }})
                            </span>
                        </h-row>
                        <h-row justify="center">
                            <span>
                                XS: {{ item.EV_XS_COUNT }} / {{ item.CLC_XS_COUNT }} (+{{ item.CLC_XS_COUNT_PREV }})
                            </span>
                        </h-row>
                        <h-row justify="center">
                            <span>
                                S: {{ item.EV_S_COUNT }} / {{ item.CLC_S_COUNT }} (+{{ item.CLC_S_COUNT_PREV }})
                            </span>
                        </h-row>
                        <h-row justify="center">
                            <span>
                                M: {{ item.EV_M_COUNT }} / {{ item.CLC_M_COUNT }} (+{{ item.CLC_M_COUNT_PREV }})
                            </span>
                        </h-row>
                        <h-row justify="center">
                            <span>
                                L: {{ item.EV_L_COUNT }} / {{ item.CLC_L_COUNT }} (+{{ item.CLC_L_COUNT_PREV }})
                            </span>
                        </h-row>
                        <h-row justify="center">
                            <span>
                                XL: {{ item.EV_XL_COUNT }} / {{ item.CLC_XL_COUNT }} (+{{ item.CLC_XL_COUNT_PREV }})
                            </span>
                        </h-row>
                        <h-row justify="center">
                            <span>
                                XXL: {{ item.EV_XXL_COUNT }} / {{ item.CLC_XXL_COUNT }} (+{{ item.CLC_XXL_COUNT_PREV }})
                            </span>
                        </h-row>
                    </h-col>
                </h-row>
            </button-tooltip>
        </template>

    </base-table>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import BaseTable from '@/components/aaaProject/table/BaseTable';
    import ClientiTableHeader from '@/components/secure/clienti/components/ClientiTableHeader';
    import ButtonTooltip from '@/components/aaaGenerics/commons/ButtonTooltip';
    import moment from '@/moment';

    export default {
        name: 'ClientiTable',
        components: {HRow, HCol, ButtonTooltip, ClientiTableHeader, BaseTable},
        props: {isLoading: Boolean},
        data: () => ({
            headers: [
                {
                    text: '#',
                    value: 'ID_REC',
                    align: 'center'
                },
                {
                    text: 'Stato ',
                    value: 'ERRORE',
                    align: 'center'
                },
                {
                    text: 'Nome ',
                    value: 'NOME',
                    align: 'center'
                },
                {
                    text: 'Inizio ',
                    value: 'CLC_MIN_INIZIO',
                    align: 'center',
                    custom: true
                },
                {
                    text: 'Scadenza ',
                    value: 'CLC_MAX_SCADENZA',
                    align: 'center',
                    custom: true
                },
                {
                    text: 'Contratti ',
                    value: 'CLC_NORM_COUNT',
                    align: 'center',
                    custom: true
                },
                {
                    text: 'Preventivi ',
                    value: 'CLC_PREV_COUNT',
                    align: 'center',
                    custom: true
                },
                {
                    text: 'Eventi Associati ',
                    value: 'EV_ALL_COUNT',
                    align: 'center',
                    custom: true
                },
                {
                    text: 'Visualizzazioni ',
                    value: 'VISUALIZZAZIONI',
                    align: 'center'
                },
                {
                    value: 'actions',
                    align: 'center',
                    sortable: false
                }
            ]
        }),
        methods: {
            onChangeOptions(options) {
                this.$emit('changeOptions', options);
            },
            getData(data, format) {
                return moment.toDate(data, !format ? 'L' : format);
            }
        }
    }
</script>

<style scoped>

</style>
