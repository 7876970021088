var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-table',{attrs:{"models-name":"clienti","model-ref":"il cliente","new-msg":"un nuovo cliente","is-loading":_vm.isLoading,"headers":_vm.headers,"can-create":_vm.$store.state.authModule.utente.CAN_CREATE_CLIENTE},on:{"changeOptions":function($event){return _vm.onChangeOptions($event)}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var onChangeFilter = ref.onChangeFilter;
return [_c('clienti-table-header',{on:{"changeOptions":function($event){return _vm.onChangeOptions($event)},"changeFilter":onChangeFilter}})]}},{key:"CLC_MIN_INIZIO",fn:function(ref){
var item = ref.item;
return [_c('i',{class:!!item.CLC_MIN_INIZIO && (_vm.getData(new Date(), 'yyyyMMDD') < _vm.getData(item.CLC_MIN_INIZIO, 'yyyyMMDD') ) ? 'error--text' : undefined},[_vm._v(" "+_vm._s(!item.CLC_MIN_INIZIO ? '-' : _vm.getData(item.CLC_MIN_INIZIO))+" ")])]}},{key:"CLC_MAX_SCADENZA",fn:function(ref){
var item = ref.item;
return [_c('i',{class:!!item.CLC_MAX_SCADENZA && (_vm.getData(new Date(), 'yyyyMMDD') > _vm.getData(item.CLC_MAX_SCADENZA, 'yyyyMMDD') ) ? 'error--text' : undefined},[_vm._v(" "+_vm._s(!item.CLC_MAX_SCADENZA ? '-' : _vm.getData(item.CLC_MAX_SCADENZA))+" ")])]}},{key:"CLC_NORM_COUNT",fn:function(ref){
var item = ref.item;
return [_c('button-tooltip',{attrs:{"x-small":"","fab":"","text":''+item.CLC_NORM_COUNT,"color":"primary","to":'/secure/contratti?cln=' + item.ID_REC,"exact":"","tooltip":item.NOME + ' possiede ' + item.CLC_NORM_COUNT + ' contratti definitivi'}})]}},{key:"CLC_PREV_COUNT",fn:function(ref){
var item = ref.item;
return [_c('button-tooltip',{attrs:{"x-small":"","fab":"","text":''+item.CLC_PREV_COUNT,"color":"primary","to":'/secure/contratti?cln=' + item.ID_REC,"exact":"","tooltip":item.NOME + ' possiede ' + item.CLC_PREV_COUNT + ' preventivi in attesa'}})]}},{key:"EV_ALL_COUNT",fn:function(ref){
var item = ref.item;
return [_c('button-tooltip',{attrs:{"fab":"","x-small":"","color":"primary","text":'' + item.EV_ALL_COUNT,"to":'/secure/eventi?cln=' + item.ID_REC,"exact":""}},[_c('h-row',[_c('h-col',{staticClass:"py-0 px-6"},[_c('h-row',{staticClass:"pb-2"},[_c('span',[_vm._v(" TOTALE: "+_vm._s(item.EV_ALL_COUNT)+" / "+_vm._s(item.CLC_ALL_COUNT)+" (+"+_vm._s(item.CLC_ALL_COUNT_PREV)+") ")])]),_c('h-row',{attrs:{"justify":"center"}},[_c('span',[_vm._v(" XS: "+_vm._s(item.EV_XS_COUNT)+" / "+_vm._s(item.CLC_XS_COUNT)+" (+"+_vm._s(item.CLC_XS_COUNT_PREV)+") ")])]),_c('h-row',{attrs:{"justify":"center"}},[_c('span',[_vm._v(" S: "+_vm._s(item.EV_S_COUNT)+" / "+_vm._s(item.CLC_S_COUNT)+" (+"+_vm._s(item.CLC_S_COUNT_PREV)+") ")])]),_c('h-row',{attrs:{"justify":"center"}},[_c('span',[_vm._v(" M: "+_vm._s(item.EV_M_COUNT)+" / "+_vm._s(item.CLC_M_COUNT)+" (+"+_vm._s(item.CLC_M_COUNT_PREV)+") ")])]),_c('h-row',{attrs:{"justify":"center"}},[_c('span',[_vm._v(" L: "+_vm._s(item.EV_L_COUNT)+" / "+_vm._s(item.CLC_L_COUNT)+" (+"+_vm._s(item.CLC_L_COUNT_PREV)+") ")])]),_c('h-row',{attrs:{"justify":"center"}},[_c('span',[_vm._v(" XL: "+_vm._s(item.EV_XL_COUNT)+" / "+_vm._s(item.CLC_XL_COUNT)+" (+"+_vm._s(item.CLC_XL_COUNT_PREV)+") ")])]),_c('h-row',{attrs:{"justify":"center"}},[_c('span',[_vm._v(" XXL: "+_vm._s(item.EV_XXL_COUNT)+" / "+_vm._s(item.CLC_XXL_COUNT)+" (+"+_vm._s(item.CLC_XXL_COUNT_PREV)+") ")])])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }