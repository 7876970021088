<template>
    <base-sezione models-name="clienti">

        <template #table="{ isLoading, onChangeOptions }">
            <clienti-table :is-loading="isLoading" @changeOptions="onChangeOptions"></clienti-table>
        </template>

    </base-sezione>
</template>

<script>
    import BaseSezione from '@/components/aaaProject/commons/BaseSezione';
    import ClientiTable from '@/components/secure/clienti/components/ClientiTable';

    export default {
        name: 'Clienti',
        components: {ClientiTable, BaseSezione}
    }
</script>

<style scoped>

</style>
